
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import { getDefaultEventTypes } from '../../helpers/default-event-types';
import { getMonthName } from '../../helpers/date';
import EventTypesGroup from './EventTypesGroup.vue';
import EventsAgenda from './EventsAgenda.vue';
import { VuetifyMixin, AuthMixin, CurrentTeamMixin, StringsMixin, LocalForageMixin, BAIconsMixin, RoutingMixin } from '@/mixins';
import { TeamEventTypeData } from '@/../types/interfaces';
import { CalendarMode, CalendarModeValues } from './types/CalendarMode';
import { VCalendarDay, VCalendarDayEventParsed } from './types/VCalendarDay';
import CalendarProvider from "@/components/hoc/CalendarProvider.vue";
import CalendarExportDialog from "@/components/calendar/CalendarExportDialog.vue";
import CalendarImportDialog from "@/components/calendar/CalendarImportDialog.vue";
import CalendarAddLinkDialog from "@/components/calendar/CalendarAddLinkDialog.vue";
import { DateTime } from "luxon";
import { BehaviorSubject } from 'rxjs';
import { CalendarModel } from "@/models/calendar/CalendarModel";
import { CalendarApi, CalendarEventsApi } from '@/api/CalendarApi';
import { notificationStore } from '@/store';

@Component({
	components: { 
		EventsAgenda,
		EventTypesGroup,
		CalendarProvider,
		CalendarExportDialog,
		CalendarImportDialog,
		CalendarAddLinkDialog,
	}
})
export default class CalendarV2 extends Mixins(VuetifyMixin, AuthMixin, CurrentTeamMixin, StringsMixin, LocalForageMixin, BAIconsMixin, RoutingMixin){
	@Prop({ type: Boolean, default: false }) loading: boolean;
	@Prop({ type: Boolean, default: false }) readOnly: boolean;
	@Prop({ type: String, default: CalendarMode.Month }) view!: string;
	@Prop({ type: Array, default: getDefaultEventTypes() }) eventTypes!: TeamEventTypeData[];
	@Prop({ type: String, default: 'primary' }) defaultEventColor: string;
	@Prop({ type: Boolean, default: false }) largeCalendar: boolean;
	

	$refs: {
		calendar: any,
		calendarProvider: CalendarProvider
	};

	localForagePersistFields: Array<string | [string, any]> = [
		['view', CalendarMode.Month],
	];

	monthViewEventList$: BehaviorSubject<CalendarEventModel[]> = new BehaviorSubject<CalendarEventModel[]>([]);

	created(): void {
		this.focusDate = this.formatDateStd(new Date())
		this.monthViewEventList$.subscribe(list => this.teamEvents = list)
	}

	get AthleteMode(): boolean{
		if(this.CurrentProfileType === 'athlete'){
			return true;
		}
		return false;
	}

	teamEvents: CalendarEventModel[] = []

	focusDate: string = '';
	get FocusDate(): string {
		return this.focusDate;
	}
	set FocusDate(value: string) {
		// TODO: Update Focus date in provider
		this.focusDate = value
	}
	updateFocusDate(focusDate: string): void{
		this.focusDate = focusDate;
	}

	showAddEvent = false;
	focusEvent = {}
	focusDay = null;
	eventFilters = this.eventTypes.filter(event => event.disabled !== true).map(event => event.name);
	types = CalendarModeValues;

	addEvent(focusDate?: string): void{
		if(this.readOnly) return;
		this.$emit('click:add-event', { focusDate });
	}

	weekdayFmt(date: string): string{
		const weekdays = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
		return `${weekdays[new Date(date).getUTCDay()]}`;
	}

	get ShowDatePicker(): boolean{
		return ["day", "week", "4day"].includes(this.view) && this.$vuetify.breakpoint.lgAndUp;
	}

	eventClicked(calendarEvent: CalendarEventModel, $event: VCalendarDay<CalendarEventModel>): void{
		this.$emit('click:event', { calendarEvent, $event });
	}

	toggleEventView(): void{
		if(this.view !== CalendarMode.Agenda){
			
			this.updateEventView(CalendarMode.Agenda);
		}else{
			this.updateEventView(CalendarMode.Month);
		}
	}
	updateEventView(view: string): void{
		this.view = view;
	}

	get CalendarId(): string {
		if( this.$refs.calendar ) return this.$refs.calendar.id;
		return undefined;
	}
	get CalendarTimeTitle(): string{
		if (this.$refs.calendar && this.view !== CalendarMode.Agenda){
			return this.$refs.calendar.title ? this.$refs.calendar.title : this.CurrentMonthName + ' ' + this.CurrentYear
		} else {
			return this.CurrentMonthName + ' ' + this.CurrentYear
		}
	}

	get ShowCalendarView(): boolean{
		return !this.ShowAgendaView;
	}
	get ShowAgendaView(): boolean{
		return this.view === CalendarMode.Agenda;
	}

	get CurrentDate(): Date{
		return new Date(this.focusDate);
	}
	get CurrentMonthName(): string{
		return getMonthName(this.CurrentDate);
	}
	get CurrentYear(): number{
		return this.CurrentDate.getUTCFullYear(); 
	}
	/**
	 * Returns only events who's type match the current filters. Events without a type are always displayed
	 */
	get FilteredEvents(): CalendarEventModel[]{
		return this.teamEvents.filter(
			event => event.eventType ? (this.eventFilters.find(
				type => type === event.eventType
			) !== undefined) : true
		);
	}
	get HeaderSheetStyle(): Record<string,any>{
		return {
			'border-bottom': `2px solid ${this.getColor('baColorGray12')} !important`,
		};
	}
	async mounted(): Promise<void>{
		this.resetDate();
        this.initCalendar();
	}

    calendarApi: CalendarApi;
    eventsApi: CalendarEventsApi;
    calendar: CalendarModel;
	calendarLoaded: boolean = false;
    async initCalendar() {
        this.calendarApi = new CalendarApi('team', this.CurrentTeamId);
        const response = await this.calendarApi.findAllWithAccess();
        if( this.IsNotEmptyArray(response) ) {
            this.calendar = response[0];
            this.eventsApi = new CalendarEventsApi('team', this.CurrentTeamId, this.calendar.id);
        }
		this.calendarLoaded = true;
    }

	loadingCalendar = false;
	load() {
		this.loadingCalendar = true;
		this.$refs.calendarProvider.loadCalendar();
		this.loadingCalendar = false;
	}

	get IsLoading(): boolean{
		return this.loading || this.loadingCalendar;
	}

	async removeCalendarEvent(calendarEvent: CalendarEventModel): Promise<void>{
		this.$emit('remove:event', { calendarEvent });
	}

	/** v-calendar events */
	getEventColor (event: CalendarEventModel): string {
		let color = this.defaultEventColor;
		if(event.eventType){
			let type = this.eventTypes.find(type => type.name === event.eventType);
			if(type && type.color) color = type.color;
		}
		return color;
	}



	onUpdateRange(): void{
		this.$emit('update:range');
	}
	showEvent($event: VCalendarDay<CalendarEventModel>): void{
		const event: CalendarEventModel = $event.event;
		this.focusEvent = $event;
		this.eventClicked(event, $event);
	}
	clickDay($event: VCalendarDay<CalendarEventModel>): void{
		this.focusDay = new Date($event.date);
		this.FocusDate = this.formatDateStd(new Date($event.date));		
		this.addEvent($event.date);
	}
	clickMore($event: VCalendarDay<CalendarEventModel>): void{
		this.FocusDate = this.formatDateStd(new Date($event.date));
		this.focusDay = new Date($event.date);
	}
	resetDate(override?: Date): void{
		if(override === undefined){
			this.FocusDate = this.formatDateStd(new Date());
		}else{
			this.FocusDate = this.formatDateStd(override);
		}
	}
	onClickPrev(): void {
		if (this.view === CalendarMode.Month || this.ShowAgendaView){
			const currentDate = new Date(this.focusDate)
			const newDate = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
			this.FocusDate = this.formatDateStd(newDate);
		} else if (this.view === CalendarMode.Week){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate() - 8;
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.FocusDate = this.formatDateStd(newDate);
		} else if (this.view === CalendarMode.Day){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()-1
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.FocusDate = this.formatDateStd(newDate);
		} else if (this.view === CalendarMode.FourDay){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()-4
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.FocusDate = this.formatDateStd(newDate);
		}
	}
	onClickNext(): void {
		if (this.view === CalendarMode.Month || this.ShowAgendaView){
			const currentDate = new Date(this.focusDate)
			const newDate = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth()+1, 1)
			this.FocusDate = this.formatDateStd(newDate);
		} else if (this.view === CalendarMode.Week){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate() + 8;
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.FocusDate = this.formatDateStd(newDate);
		} else if (this.view === CalendarMode.Day){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()+1
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.FocusDate = this.formatDateStd(newDate);
		} else if (this.view === CalendarMode.FourDay){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()+4
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.FocusDate = this.formatDateStd(newDate);
		}
	}
	onClickCollapse() {
		this.$emit('collapse:Calendar');
	}

	addLinkDialogVisible= false;
	async toggleAddLinkView(){
		this.addLinkDialogVisible  = !this.addLinkDialogVisible;
	}

	exportDialogVisible = false;
	copyClicked = false;
	async toggleExportDialogVisiblility() {
		this.exportDialogVisible  = !this.exportDialogVisible;
	}

	importDialogVisible = false;
	async toggleCalendarImport() {
		this.importDialogVisible = !this.importDialogVisible
	}
	importingEvents: boolean = false;
	importLength: number = 0;
	importCount: number = 0;
	async onImportData(newEvents: CalendarEventModel[]) {
		this.importingEvents = true;
		this.importLength = newEvents.length;
		this.importCount = 0;
        for( const event of newEvents ) {
            await this.eventsApi.insert(event);
            this.importCount++;
        }
		this.importingEvents = false;
		notificationStore.pushSnackbarSuccess({message: `Created ${this.importCount} events`});
		this.load();
	}

	/**
	 * Returns a time output for the calendar
	 * @param eventParsed Parsed Event passed from v-calendar slot
	 */
	calendarTimeOutput(eventParsed: VCalendarDayEventParsed): string {
		const start = eventParsed.start;
		let format = "h"
		format += start.minute === 0 ? '' : ':mm'
		format += ' a'
		return DateTime.fromString(start.time, 'T').toFormat(format);
	}
}
